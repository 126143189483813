import { ReactTypeformEmbed } from 'react-typeform-embed';
import React, { useCallback, useEffect, useRef } from 'react';

const Typeform = ({ data, pricing }) => {
  const formRef = useRef(null);

  useEffect(() => {
    (async () => {
      const { makePopup } = await import('@typeform/embed');

      formRef.current = makePopup(data.typeform, {
        size: 65,
      });
    })();
  }, []);

  const openForm = useCallback(() => {
    formRef.current?.open();
  }, [formRef]);

  return (
    <>
      {pricing ? (
        <div className="pricing__action ">
          {data.popUp ? (
            <button id="bt-popup" onClick={openForm} className="button button--primary inline-block">
              {data.button}
            </button>
          ) : (
            <ReactTypeformEmbed url={data.typeform} style={{ height: 500, position: 'initial', margin: '0 auto' }} />
          )}
        </div>
      ) : (
        <div className="container flex justify-center">
          {data.popUp ? (
            <button id="bt-popup" onClick={openForm} className="button button--primary mb-4 ">
              {data.button}
            </button>
          ) : (
            <ReactTypeformEmbed url={data.typeform} style={{ height: 500, position: 'initial', margin: '0 auto' }} />
          )}
        </div>
      )}
    </>
  );
};

export default Typeform;
