import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';

const PhoneInputComponent = (props) => {
  const [value, setValue] = useState();
  const [successMessage, setSuccessMessage] = useState('');
  const [errors, setErrors] = useState('');
  const { currentNodeLocale, phoneInputWithModal, phoneInputData } = props;

  const phoneInputContent = phoneInputWithModal ? phoneInputWithModal.reference : phoneInputData;

  const errorsHandler = (error) => {
    setErrors(error);
  };

  const sendSMS = async () => {
    if (!value) {
      errorsHandler(phoneInputContent.errors[0].errorMessage.childMarkdownRemark.rawMarkdownBody);
    } else {
      const response = await fetch('https://europe-west2-gaius-singlepage-1.cloudfunctions.net/twilioSendSMS', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ currentNodeLocale: currentNodeLocale, SMSreceiver: value }),
      });
      response.status === 200
        ? (setSuccessMessage(phoneInputContent.errors[2].errorMessage.childMarkdownRemark.rawMarkdownBody),
          setValue(),
          setErrors(''))
        : errorsHandler(phoneInputContent.errors[1].errorMessage.childMarkdownRemark.rawMarkdownBody);
    }
  };

  return (
    <>
      <div className={`phoneInput`}>
        <div className="mb-4 lg:mb-0 lg:mr-4 flex-0">
          <PhoneInput enableSearch={true} value={value} onChange={setValue} country={'de'} />
        </div>
        <div className={`button button--primary w-1/2 m-auto lg:w-auto lg:m-0`} onClick={() => sendSMS()}>
          {phoneInputContent.buttonText}
        </div>
      </div>
      <div className="error">
        {errors && <ReactMarkdown source={errors} disallowedTypes={['paragraph']} unwrapDisallowed={true} />}
      </div>
      <div className="success">
        {successMessage && (
          <ReactMarkdown source={successMessage} disallowedTypes={['paragraph']} unwrapDisallowed={true} />
        )}
      </div>
      <div className={`phoneInput-CTA font-bold flex justify-center `}>
        <svg
          className="mt-1"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          viewBox="0 0 16 16"
        >
          <path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm8-7A8 8 0 1 1 0 8a8 8 0 0 1 16 0z" />
          <path
            fillRule="evenodd"
            d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z"
          />
        </svg>

        <div className="ml-2 my-auto">{phoneInputContent.cta}</div>
      </div>
    </>
  );
};

export default PhoneInputComponent;
